// blog_detail max 77rem

.blog_detail {

  .blog_header {

    h1 {
        padding: 3rem;
    }
  }
}