// divider_cover max 77rem

.divider_cover {

  .divider_cover_container {

    .divider_cover_headlines {
      width: 70%;
      padding: 1rem;

      h2 {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}