// cover max 77rem

.cover {

  .cover_container {

    .cover_headlines {

      h1 {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}