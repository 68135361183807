// basic styles
//@import "style_base.scss";

//breakpoint max-width: 77rem
@media screen and (max-width: 77rem) {
  @import "general SCSS files/menu_max_77rem";
  @import "ContentElements/blog_detail/blog_detail_max_77rem.scss";
  @import "ContentElements/cover/cover_max_77rem.scss";
  @import "ContentElements/divider_cover/divider_cover_max_77rem.scss";
  @import "ContentElements/roundimage_text/roundimage_text_max_77rem.scss";
  @import "ContentElements/service/service_max_77rem.scss";
  @import "ContentElements/text_image_divider/text_image_divider_max_77rem";
}
