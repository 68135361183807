// roundimage_text max 77rem

.workflow {
  padding: 0 1rem;

  .workflow_container {

    .workflow_item {

      img, .dummy {
        height: calc((100vw - 9rem)/7);
      }

      .workflow_overlay {

        height: calc((100vw - 9rem)/7);
      }
    }

    .workflow_line {
      margin-top: calc((100vw - 8rem)/100 * 7.14275);
    }
  }
}