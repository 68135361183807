// text_image_divider max 77rem

.textimagedivider {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    .textimagedivider_image {
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        grid-column: 2/3;
    }

    .textimagedivider_text {
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        -ms-grid-row: 1;
        grid-column: 1/2;
        grid-row: 1;
    }
}